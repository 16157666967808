import styled from "styled-components"
import { Link } from "gatsby"

export const Btn = styled(Link)`
  padding: 10px 60px;
  background-color: ${props => props.theme.colors.primary} !important;
  border-radius: 0;
  font-weight: 700;
  font-size: 1.5rem;
  border-radius: 0;
  font-size: 1.5rem;
  margin-top: 20px;
  padding: 15px 60px;
  color: white;
  &:hover,
  &:focus,
  &:active {
    color: white;
    text-decoration: none;
    background-color: #f59443;
    border: none;
    box-shadow: none !important;
  }
  &:active {
    opacity: 0.8;
  }
`
