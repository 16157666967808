import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import BackgroundImage from "gatsby-background-image"

const StyledImage = styled(BackgroundImage)`
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .overlay {
    width: 100%;
    padding: 30px 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
`

export const BgImage = props => {
  const data = useStaticQuery(graphql`
    query {
      fileName: contentfulSetting(name: { eq: "headband" }) {
        image {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyContentfulFluid
          }
        }
        name
      }
    }
  `)
  return (
    <StyledImage
      Tag="section"
      fluid={data.fileName.image.fluid}
      backgroundColor={`#040e18`}
    >
      <div className="overlay">{props.children}</div>
    </StyledImage>
  )
}
